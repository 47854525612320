import { getQueryUrl } from "@Shared/helpers/api";
import { apiSlice } from "@Shared/app/api/apiSlice";
import { WrappedResponse } from "../../common/models";
import _ from "lodash";

export interface ClubAddressDto {
    city: string | null
    street: string | null
}

export interface ClubDto {
    id: number
    regionId: number
    title: string
    shortTitle: string | null
    phone: string | null
    email: string
    address: ClubAddressDto  
    memberCount: number
}

export interface ClubGetRequest {
    regionId?: number | null
    name?: string | null
    organizationIds?: number[] | null
    withCurrentClub?: boolean
    phone?: string | null
    email?: string | null
    city?: string | null
}
export type ClubGetResponse = WrappedResponse<ClubDto[]>;

export interface AddClubRequest {
    title: string
    shortTitle: string
    phone: string
    email: string
    address: {
        city: string
        street: string
    }
    regionId: number
}

export interface UpdateClubRequest {
    id: number
    title?: string
    IsUpdateShortTitle?: boolean
    shortTitle?: string
    phone?: string
    email?: string
    address?: {
        city?: string
        street?: string
    }
    regionId?: number
}

export const clubsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        clubGet: builder.query<ClubGetResponse, ClubGetRequest>({
            query(request) {

                const finalRequest = {
                    ...request,
                    organizationIds: request.organizationIds?.map(id => `${id}`).join()
                };

                return getQueryUrl(['v1', 'clubs'], finalRequest);
            },
            providesTags: (_1, _2, request) => [
                "Clubs", 
                ...(!_.isNil(request.organizationIds) ? (["OrganizationClubs"] as const) : ([] as const))
            ]
        }),

        clubCountGet: builder.query<WrappedResponse<number>, ClubGetRequest>({
            query: (request) => {
                const finalRequest = {
                    ...request,
                    organizationIds: request.organizationIds?.map(id => `${id}`).join()
                };
                return getQueryUrl(['v1', 'clubs', 'count'], finalRequest);
            },
            providesTags: (_1, _2, request) => [
                "Clubs", 
                ...(!_.isNil(request.organizationIds) ? (["OrganizationClubs"] as const) : ([] as const))
            ]
        }),

        clubById: builder.query<WrappedResponse<ClubDto>, number>({
            query: (id) => {
                return getQueryUrl(['v1', 'clubs', id]);
            },
            providesTags: ['Clubs']
        }),

        deleteClub: builder.mutation<WrappedResponse<boolean>, number>({
            query: (id) => ({
                url: getQueryUrl(['v1', 'clubs', id]),
                method: 'DELETE'
            }),
            invalidatesTags: ['Clubs']
        }),

        addClub: builder.mutation<WrappedResponse<number>, AddClubRequest>({
            query: (body) => ({
                url: getQueryUrl(['v1', 'clubs']),
                method: 'POST',
                body: body
            }),
            invalidatesTags: ['Clubs']
        }),

        updateClub: builder.mutation<WrappedResponse<boolean>, UpdateClubRequest>({
            query: (body) => ({
                url: getQueryUrl(['v1', 'clubs']),
                method: 'PUT',
                body: body
            }),
            invalidatesTags: ['Clubs']
        }),

        removeMemberFromClub: builder.mutation<WrappedResponse<boolean>, {memberId: number}>({
            query: (body) => ({
                url: getQueryUrl(['v1', 'clubs', 'member', 'remove']),
                method: 'POST',
                body: body,

            }),
            invalidatesTags: ['Members']
        }),
        
        addMemberToClub: builder.mutation<WrappedResponse<boolean>, { clubId: number, memberId: number }>({
            query: (body) => ({
                url: getQueryUrl(['v1', 'clubs', 'member', 'add']),
                method: 'POST',
                body: body,

            }),
            invalidatesTags: ['Members']
        })
    })
});

export const { 
    useClubGetQuery,
    useLazyClubGetQuery,
    useClubCountGetQuery,
    useClubByIdQuery,
    useDeleteClubMutation,
    useRemoveMemberFromClubMutation,
    useAddMemberToClubMutation,
    useAddClubMutation,
    useUpdateClubMutation
} = clubsApiSlice